angular.module('fingerink')
    .factory('directDebitService', ['$http', function ($http) {
        var sdo = {

            getAccount: function () {
                return $http({
                    method: 'GET',
                    url: 'directDebit',
                    crossDomain: true
                });
            },

            updateAccount: function (account) {
                return $http({
                    method: 'PUT',
                    url: 'directDebit',
                    crossDomain: true,
                    data: account
                });
            }


        };
        return sdo;
    }]);
